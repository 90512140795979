<template>
    <div class="d-flex flex-grow-1">
        <div class="d-flex flex-column flex-grow-1 w-50">
            <div class="m-1 p-1 card">
                <span class="fs-4 pb-2">Input Devices</span>
                <div v-for="input in inputs" :key="input.id">
                    <div>
                        <input
                            type="checkbox"
                            :id="input.id"
                            v-model="input.listen"
                            @change="listen(input.id)"
                        />
                        {{ input.manufacturer }} - {{ input.name }}
                    </div>
                </div>
            </div>
            <div class="flex-grow-1 m-1 p-1 card">
                <div class="d-flex justify-content-between align-items-center pb-2">
                    <span class="fs-4">Input Log</span>
                    <div>
                        <button
                            type="button"
                            class="btn btn-sm btn-primary"
                            @click="data.input = []"
                        >
                            Clear
                        </button>
                    </div>
                </div>
                <table class="table table-bordered table-hover">
                    <tr>
                        <th class="cell-data">Time</th>
                        <th class="cell-text">Source</th>
                        <th class="cell-text">Message</th>
                        <th class="cell-data">Channel</th>
                        <th class="cell-data">Data</th>
                        <th class="cell-data">Velocity</th>
                    </tr>
                    <tr v-for="data in data.input" :key="data.timestamp">
                        <td>{{ parseInt(data.timestamp) }}</td>
                        <td>{{ data.target.name }}</td>
                        <td>{{ data.type }}</td>
                        <td>{{ data.channel }}</td>
                        <td>{{ getData(data) }}</td>
                        <td>{{ getVelocity(data) }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="d-flex flex-column flex-grow-1 w-50">
            <div class="m-1 p-1 card">
                <div class="d-flex justify-content-between align-items-center pb-2">
                    <span class="fs-4">Output Devices</span>
                    <div>
                        <button type="button" class="btn btn-sm btn-primary" @click="outputAdd">
                            Add Output
                        </button>
                    </div>
                </div>
                <div v-for="output in outputs" :key="output.id">
                    {{ output.manufacturer }} - {{ output.name }}
                </div>
                <div v-for="outputMessage in outputMessages" :key="outputMessage.id">
                    <div class="d-flex justify-content-between align-items-center mb-1 output-item">
                        <input
                            class="flex-grow-1 mx-1"
                            type="text"
                            placeholder="Enter raw MIDI message to send e.g. 0x95 0x00 0x7f"
                            v-model="outputMessage.data"
                            :key="outputMessage.id"
                        />
                        <div>
                            <button
                                class="btn btn-sm btn-primary mx-1"
                                @click="outputSend(outputMessage.id)"
                            >
                                Send
                            </button>
                            <button
                                class="btn btn-sm btn-primary"
                                @click="outputRemove(outputMessage.id)"
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-grow-1 m-1 p-1 card">
                <span class="fs-4 pb-2">Output Log</span>
                <div v-for="data in data.output" :key="data.timestamp">
                    {{ data.channel }} - {{ data.type }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import WebMidi from 'webmidi'

export default {
    name: 'Home',
    data: function() {
        return {
            showButton: true,
            inputs: [],
            outputs: [],
            data: {
                input: [],
                output: []
            },
            outputMessages: [],
            types: [
                'noteoff',
                'noteon',
                'keyaftertouch',
                'controlchange',
                'channelmode',
                'nrpn',
                'programchange',
                'channelaftertouch',
                'pitchbend',
                'sysex'
            ]
        }
    },
    methods: {
        listen(deviceId) {
            const channels = 'all'
            const input = this.inputs.filter(obj => {
                return obj.id === deviceId
            })[0]

            if (input.listen) {
                this.types.forEach(message => {
                    input.addListener(message, channels, this.onInput)
                })
            } else {
                this.channelMesages.forEach(message => {
                    input.removeListener(message, channels, this.onInput)
                })
            }
        },
        onInput(e) {
            this.data.input.unshift(e)
        },
        outputAdd() {
            this.outputMessages.push({
                id: this.outputMessages.length,
                data: ''
            })
        },
        outputSend(outputMessageId) {
            const outputMessage = this.outputMessages.filter(obj => {
                return obj.id === outputMessageId
            })[0]
            const bytes = outputMessage.data.split(/[\s,]+/).map(e => parseInt(e))
            const status = bytes[0]
            const data = bytes.slice(1)
            try {
                this.outputs[0].send(status, data)
            } catch (error) {
                console.log(error.message)
            }
        },
        outputRemove(id) {
            this.outputMessages = this.outputMessages.filter(e => e.id !== id)
        },
        getData(data) {
            if (data.type === 'noteon' || data.type === 'noteoff') {
                return data.note.number
            } else if (data.type === 'controlchange') {
                return data.controller.number
            }
        },
        getVelocity(data) {
            if (data.type === 'noteon' || data.type === 'noteoff') {
                return data.rawVelocity
            } else if (data.type === 'controlchange') {
                return data.value
            }
        }
    },
    mounted() {
        WebMidi.enable(err => {
            if (err) {
                console.log('WebMidi could not be enabled.', err)
            }

            this.inputs = WebMidi.inputs
            this.outputs = WebMidi.outputs

            this.inputs.forEach(input => {
                input.listen = true
                this.listen(input.id)
            })
        }, true)
    }
}
</script>

<style scoped>
td,
.btn,
.output-item {
    font-family: 'Courier New', Courier, monospace;
}
.cell-data {
    width: 80px;
}
.cell-text {
    width: auto;
}
</style>
