<template>
    <div class="d-flex flex-column vh-100">
        <div id="nav" class="d-flex justify-content-between">
            <strong class="fs-2">MIDI MONSTER</strong>
            <div>
                <!-- <router-link to="/">Home</router-link> | -->
                <!-- <router-link to="/about">About</router-link> -->
            </div>
        </div>
        <div class="d-flex flex-grow-1">
            <router-view />
        </div>
    </div>
</template>

<style>
#nav {
    padding: 10px;
}
.flex-basis-0 {
    flex-basis: 0;
}
</style>
